import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import ImageWrap from 'components/ImageWrap'
import { strings } from 'js/strings'

export default function AboutTemplate({ data }) {
  const page = data.wpPage
  const events = page.template.events.events

  return (
    <Layout
      bodyClassName='page-template page-template-page-builder page-builder page page-id-24628 events app-data index-data singular-data page-data page-24628-data page-events-data page-builder-data'
      page={page}
    >
      <EventsList events={events} />
    </Layout>
  )
}

const EventsList = ({ events }) => {
  return (
    <section className='events-grid'>
      <div className='events-grid__flex'>
        {events.map((event, i) => (
          <Event key={i} content={event.event} />
        ))}
      </div>
    </section>
  )
}

const Event = ({ content }) => {
  const { category, dateString, excerpt, image, link, title } = content
  return (
    <div className='event'>
      {image && (
        <ImageWrap
          className='event__img'
          alt='event background image'
          image={image}
        />
      )}

      <div className='event__content'>
        <h6 className='event__small'>{dateString}</h6>

        <h3 className='event__large '>{title}</h3>

        <p className='event__name'>{category}</p>

        <p className='event__address'>{excerpt}</p>
      </div>
      {link && (
        <a href={link} className='event__link' target='"blank"'>
          {strings.register}
        </a>
      )}
    </div>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      ...SeoPage
      ...BannerPage
      ...RelatedBlockPage
      ...ContactBlockPage
      template {
        ... on WpTemplate_Events {
          templateName
          events {
            events {
              event {
                image {
                  ...GatsbyImage
                }
                dateString
                title
                category
                excerpt
                link
              }
            }
          }
        }
      }
    }
  }
`
